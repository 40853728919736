<template>
  <div class="DApp-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{ $t('DAPP_PAGE_TITLE') }}</h1>
          </CCol>
          <CCol md="6" sm="12"></CCol>
          <CCol md="3" sm="12">
            <CButton class="btn btn-success btn-add-new" @click="addNewDapp()">+ {{ $t('DAPP_PAGE_ADD_NEW') }}</CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchDAppHeader :onSearch="onSearch" />
        <DAppListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isAllowedDel="isAllowedDel"
          :isAllowedViewDetail="isAllowedViewDetail"
          :getDApp="getDApp"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { endpoints, env } from '@/constants';
import DAppListTable from '@/components/DApp/DAppListTable';
import SearchDAppHeader from '@/components/DApp/SearchDAppHeader';
import StakingPagination from '@/components/StakingPagination';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'DAppList',
  components: {
    SearchDAppHeader,
    DAppListTable,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      questionCatList: [],
      questionTypeList: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDel: false,
      isAllowedViewDetail: false,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'DELETE_DAPP') {
            this.isAllowedDel = true;
          }
          if (item.name === 'VIEW_DAPP_DETAIL') {
            this.isAllowedViewDetail = true;
          }
        });
      }
    },
    async getDApp(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          name: filterData.name,
          address: filterData.address,
        };
        const result = await this.$http.get(endpoints.getDappList, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DAPP_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getDApp();
    },

    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getDApp();
    },

    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getDApp(data);
    },
    addNewDapp() {
      this.$router.push('dapp/create');
    },
  },
};
</script>

<style lang="scss">
.DApp-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
  .btn-add-new {
    border: none;
    border-radius: 32px;
    height: 32px;
    padding: 0 30px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    border-radius: 17.5px;
    min-width: 175px;
    display: block;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    border: 1px solid #1a2958;
    border-radius: 17.5px;
    background: #3b4b7e;
    float: right;
  }
}
</style>
