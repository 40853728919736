<template>
  <div class="search-dapp-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="DAPP_PAGE_NAME_FIELD" v-slot="{ errors }" rules="max:100">
            <CInput :label="$t('DAPP_PAGE_NAME_FIELD')" type="text" v-model="searchData.name" class="form-group" @keyup.enter="search()" />
            <CRow>
              <CCol :md="12">
                <span class="error-msg-wrap">
                  <span class="error-msg">{{ errors[0] }}</span>
                </span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider name="DAPP_PAGE_ADDRESS_FIELD" v-slot="{ errors }" rules="max:100">
            <CInput
              :label="$t('DAPP_PAGE_ADDRESS_FIELD')"
              type="text"
              v-model="searchData.address"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg-wrap">
                  <span class="error-msg">{{ errors[0] }}</span>
                </span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <div class="col-md-6 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('DAPP_PAGE_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('DAPP_PAGE_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'SearchDAppHeader',
  components: {},
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterDApp) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        name: '',
        address: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterDApp, this.searchData);

      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  },
};
</script>
>

<style lang="scss">
.search-dapp-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
