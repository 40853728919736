<template>
  <div class="dapp-table-container">
    <CCard class="dapp-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>

        <template #address="{item}">
          <td>{{ item.address | truncate(20) }}</td>
        </template>

        <template #icon="{item}">
          <td><img class="dapp-image" :src="item.icon" /></td>
        </template>

        <template #created_at="{item}">
          <td>{{ item.created_at | moment(dateFormat) }}</td>
        </template>

        <template #is_default_flg="{item}">
          <td>
            <CIcon v-if="item.is_default_flg" class="fixed-rate-icon" name="cil-check" />
          </td>
        </template>
        <template #action="{item}">
          <td class="buttons" width="120px">
            <button class="btn btn-view-details" @click="viewDetails(item)" v-if="isAllowedViewDetail">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
            <CButton @click="openModal(item)" v-if="isAllowedDel">
              <img src="@/assets/images/ico-del-user.svg" alt class="hand edit-icon" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class>
        <div class="pb-4 tit-dele">
          <p>{{ $t('DAPP_PAGE_MODAL_DELETE_TITLE') }}</p>
        </div>
        <div class="text-modal" v-html="$t('DAPP_PAGE_MODAL_DELETE_CONTENT')"></div>
      </div>
      <template #footer>
        <CButton class="cancel-btn" @click="warningModal = false" color="dark" variant="outline">{{
          $t('DAPP_PAGE_MODAL_DELETE_BUTTON_CANCEL')
        }}</CButton>
        <CButton @click="del(id)" class="delete-btn">{{ $t('DAPP_PAGE_MODAL_DELETE_BUTTON_DELETE') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';

export default {
  name: 'DappListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'name',
          label: this.$t('DAPP_PAGE_NAME_FIELD'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'address',
          label: this.$t('DAPP_PAGE_ADDRESS_FIELD'),
        },
        {
          key: 'icon',
          label: this.$t('DAPP_PAGE_ICON_FIELD'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'is_default_flg',
          label: this.$t('DAPP_PAGE_DEFAULT_FLG_FIELD'),
        },
        {
          key: 'created_at',
          label: this.$t('DAPP_PAGE_CREATE_AT_FIELD'),
        },
        {
          key: 'action',
          label: this.$t('DAPP_PAGE_ACTION_FIELD'),
          _style: 'text-align: center;',
        },
      ],
      warningModal: false,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedDel: {
      type: Boolean,
      default: false,
    },
    isAllowedViewDetail: {
      type: Boolean,
      default: false,
    },
    getDApp: {
      type: Function,
      default: null,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`dapp/${item.id}/details`);
    },
    async del(id) {
      try {
        await this.$http.delete(endpoints.deleteDapp(id));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DAPP_PAGE_DELETE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('DAPP_PAGE_DELETE_SUCCESSFUL'),
        });
        this.warningModal = false;
        this.getDApp();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DAPP_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    openModal(value) {
      this.id = value.id;
      this.warningModal = true;
    },
  },
};
</script>

<style lang="scss">
.dapp-table-container {
  .dapp-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .dapp-image {
        width: 30px;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }
    }
  }
  .modal-body {
    padding: 1.3rem 1.5rem;
    .tit-dele {
      p {
        font-size: 18px;
      }
    }
  }

  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
